var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"bbr-meals--page"},[_c('main-app-bar',{scopedSlots:_vm._u([{key:"title",fn:function(){return [(_vm.$attrs.id)?_c('span',[_vm._v(" "+_vm._s(_vm._f("readableEnums")(_vm.selectedData.body_type,_vm.bodyTypes))+" "+_vm._s(_vm.selectedData.restriction_code)+" ")]):_c('span',[_vm._v(" Create New Meal Guide ")])]},proxy:true},{key:"actions",fn:function(){return [_c('arrow-button',{attrs:{"done":_vm.stage > 1,"to":{
          name: 'form.meals.details',
          params: { id: _vm.$attrs.id },
        },"disabled":!_vm.next,"arrow-right":"","replace":""}},[_vm._v(" Details ")]),_c('arrow-button',{attrs:{"done":_vm.stage > 2,"to":{
          name: 'form.meals.guide',
          params: { id: _vm.$attrs.id },
        },"disabled":!_vm.next,"arrow-left":"","arrow-right":"","replace":""}},[_vm._v(" Meal Guide ")]),_c('arrow-button',{attrs:{"done":_vm.stage > 3,"to":{
          name: 'form.meals.preview',
          params: { id: _vm.$attrs.id },
        },"disabled":!_vm.next,"arrow-left":"","replace":""}},[_vm._v(" Preview ")])]},proxy:true}])}),_c('div',{staticClass:"mt-5 px-lg-12 px-md-6 px-5"},[_c('transition',{attrs:{"name":"fade","mode":"out-in"}},[_c('router-view')],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }