<template>
  <div class="bbr-meals--page">
    <main-app-bar>
      <template v-slot:title>
        <span v-if="$attrs.id">
          {{ selectedData.body_type | readableEnums(bodyTypes) }}
          {{ selectedData.restriction_code }}
        </span>
        <span v-else> Create New Meal Guide </span>
      </template>
      <template v-slot:actions>
        <arrow-button
          :done="stage > 1"
          :to="{
            name: 'form.meals.details',
            params: { id: $attrs.id },
          }"
          :disabled="!next"
          arrow-right
          replace
        >
          Details
        </arrow-button>

        <arrow-button
          :done="stage > 2"
          :to="{
            name: 'form.meals.guide',
            params: { id: $attrs.id },
          }"
          :disabled="!next"
          arrow-left
          arrow-right
          replace
        >
          Meal Guide
        </arrow-button>

        <arrow-button
          :done="stage > 3"
          :to="{
            name: 'form.meals.preview',
            params: { id: $attrs.id },
          }"
          :disabled="!next"
          arrow-left
          replace
        >
          Preview
        </arrow-button>
      </template>
    </main-app-bar>

    <div class="mt-5 px-lg-12 px-md-6 px-5">
      <transition name="fade" mode="out-in">
        <router-view />
      </transition>
    </div>
  </div>
</template>

<script>
import ArrowButton from '@/components/elements/ArrowButton'
import MainAppBar from '@/layouts/shared/MainAppBar'
import { mapActions, mapState, mapMutations } from 'vuex'

export default {
  name: 'MealDetailPage',

  components: {
    MainAppBar,
    ArrowButton,
  },

  data() {
    return {
      isSaved: false,
    }
  },

  computed: {
    ...mapState({
      filter: (state) => state.meals.filter,
      bodyTypes: (state) => state.meals.extra.bodyTypes,
      selectedData: (state) => state.meals.selectedData,
    }),

    next() {
      return this.$attrs.id || false
    },

    stage() {
      return this.$route.meta ? this.$route.meta.stage : 1
    },
  },

  async created() {
    let content = this.$attrs.id

    if (content) {
      await this.fetchData(content)
    }
  },

  methods: {
    ...mapActions({
      getSingleData: 'meals/getSingleData',
    }),

    ...mapMutations({
      clearList: 'meals/clearList',
    }),

    async fetchData(id) {
      await this.getSingleData(id)
    },
  },

  beforeRouteLeave(to, from, next) {
    this.clearList()

    next()
  },
}
</script>
